import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import MyLink from "../../../components/myLink"
import { v4 as uuid } from "uuid"
import * as styles from "./style.module.scss"
import { Link } from "gatsby"
import Helmet from "react-helmet"

const OpenVacancies = () => {
  const [workableScript, setWorkableScript] = useState("")

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setWorkableScript(`
  //   whr(document).ready(function () {
  //     whr_embed(434980, {
  //       detail: "descriptions",
  //       base: "locations",
  //       zoom: "state",
  //     })
  //   })`)
  //   }, 1000)
  //   return () => clearTimeout(timer)
  // }, [])

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <div className={styles.mobileVaccancies}>
          <h3>Open Vacancies</h3>
          <a href="https://apply.workable.com/actionstep/" target="_blank">
            View Vacancies
          </a>
        </div>
        <iframe
          className={styles.iFrameContainer}
          src="https://apply.workable.com/actionstep/"
          name="workable-iframe"
          scrolling="yes"
          frameBorder="0"
          height="100%"
          width="100%"
        ></iframe>
      </div>
    </div>
  )
}

export default OpenVacancies
