import React from "react"
import Layout from "../components/layout"
import SEO from "../components/careers/seo"
import HeroHeader from "../components/careers/heroHeader"
import Benifits from "../components/careers/benifits"
import OpenVacancies from "../components/careers/openVacancies"
import CareersTestimonials from "../components/careers/careersTestimonials"
import BottomCta from "../components/careers/bottomCta"

const Careers = () => {
  return (
    <Layout>
      <SEO />
      <HeroHeader />
      <Benifits />
      <OpenVacancies />
      <CareersTestimonials />
      <BottomCta />
    </Layout>
  )
}

export default Careers
