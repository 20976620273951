// extracted by mini-css-extract-plugin
export var container = "style-module--container--tFcH2";
export var iFrameContainer = "style-module--iFrameContainer--hlfYO";
export var mobileVaccancies = "style-module--mobileVaccancies--LcedZ";
export var section = "style-module--section--cBRq0";
export var title = "style-module--title--qdTOJ";
export var vacancies = "style-module--vacancies--RWAvY";
export var vacancy = "style-module--vacancy--c9jVH";
export var vacancyBottom = "style-module--vacancyBottom--yvGGN";
export var vacancyButton = "style-module--vacancyButton--d-D0c";
export var vacancyDescription = "style-module--vacancyDescription--BlYpG";
export var vacancyTitle = "style-module--vacancyTitle--rf6EZ";
export var viewMoreButton = "style-module--viewMoreButton--7zpvf";