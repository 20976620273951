import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import * as styles from "./style.module.scss"

SwiperCore.use([Navigation])

const CareersTestimonials = () => {
  const {
    sanityCareersPage: {
      careerTestimonials: { testimonials: data },
    },
  } = useStaticQuery(graphql`
    query CareersTestimonialQuery {
      sanityCareersPage {
        careerTestimonials {
          testimonials {
            _key
            description
            name
            profileImage {
              asset {
                url
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <Swiper
          wrapperTag="ul"
          spaceBetween={50}
          slidesPerView={1}
          className={styles.swiperContainer}
          navigation
        >
          {data.map(testimonial => (
            <SwiperSlide
              key={testimonial._key}
              className={styles.testimonial}
              tag="li"
            >
              {testimonial.profileImage ? (
                <GatsbyImage
                  image={testimonial.profileImage.asset.gatsbyImageData}
                  alt={testimonial.profileImage.asset.originalFilename}
                  className={styles.profileImage}
                />
              ) : (
                <div className={styles.profileImage}></div>
              )}
              <div className={styles.content}>
                <p className={styles.description}>
                  {testimonial.description || ""}
                </p>
                <h3 className={styles.name}>{testimonial.name || ""}</h3>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default CareersTestimonials
