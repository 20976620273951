import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./style.module.scss"
import { v4 as uuid } from "uuid"

const Benifits = () => {
  const {
    sanityCareersPage: {
      careerBenifits: { benifits: data },
    },
  } = useStaticQuery(graphql`
    query CareersBenifitsQuery {
      sanityCareersPage {
        careerBenifits {
          benifits {
            title
            description
            featureIcon {
              asset {
                url
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className={styles.section}>
      <div className={styles.wrapper}>
        <div className={styles.benifits}>
          {data.map(benifit => (
            <div className={styles.benifit} key={uuid()}>
              {benifit.featureIcon && (
                <GatsbyImage
                  image={benifit.featureIcon.asset.gatsbyImageData}
                  alt={benifit.featureIcon.asset.originalFilename}
                  className={styles.benifitImage}
                />
              )}
              <h3 className={styles.benifitTitle}>{benifit.title || ""}</h3>
              <p className={styles.benifitDescription}>
                {benifit.description || ""}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Benifits
