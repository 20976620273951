import React from "react"
import BlocksContent from "./blocksContent"
import "./cta.sass"

const CTA = ({ firstColumn, secondColumn, mobileReverse, header = false }) => {
  return (
    <div className={`cta${mobileReverse ? " mobile-reverse" : ""}${header ? " header" : ""}`}>
      <div className="first-column">
        <BlocksContent blocks={firstColumn} />
      </div>
      <div className="second-column">
        <BlocksContent blocks={secondColumn} />
      </div>
    </div>
  )
}

export default CTA
