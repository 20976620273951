import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import CTA from "../../cta"
import * as styles from "./style.module.scss"

const BottomCta = () => {
  const {
    sanityCareersPage: { bottomCta: data },
  } = useStaticQuery(graphql`
    query CareersBottomCtaQuery {
      sanityCareersPage {
        bottomCta {
          firstColumn {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
          secondColumn {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
          reverse
        }
      }
    }
  `)

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <CTA
          firstColumn={data.firstColumn._rawColumnContent}
          secondColumn={data.secondColumn._rawColumnContent}
          reverse={data.reverse}
        />
      </div>
    </div>
  )
}

export default BottomCta
